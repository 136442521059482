<template>
  <div class="modal bg-opacity-50 bg-black" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Profile Characters</h5>
          <span aria-hidden="true" role="button" @click="close">&times;</span>
        </div>
        <div class="modal-body">
          <data-table
              :actions="actions"
              :headers="headers"
              :items="this.characters"/>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" @click="close">Close</button>
        </div>
      </div>
    </div>
    <EquipmentsModal 
        :data="this.equipmentData" 
        v-if="this.equipmentData.show" 
        :api="this.api"
        :server="this.server"></EquipmentsModal>



  </div>


</template>

<script>
import DataTable from "data-table/src/components/DataTable";
import EquipmentsModal from "./EquipmentsModal";

export default {
  name: "ProfileCharacterModal",
  data(){
    return {
      trigger:{},
      orderid:"",
      coinValue:20,
      showAdd:false,
      showReward:false,
      products:[],
      headers: {
        id : "ID",
        name: "Name",
      },
      actions: [
        {
          classNames:"fa-solid fa-shield text-warning",
          callback: this.equipments
        },
      ],
      characters:{},
      equipmentData:{
          show:false,
          entity:null,
      },
      itemsData:{
          show:false,
          entity:null,
      },
    }
  },
  props:{
    data: {
      entity:Object,
      show:Boolean
    },
    api: Object,
    server: Number
  },
  components:{
    DataTable,EquipmentsModal
  },
  methods:{
    async equipments(entity){
        this.equipmentData.entity = entity;
        this.equipmentData.show = true;
        this.equipmentData.isAdd = true;
    },
    async items(entity){
        this.itemsData.entity = entity;
        this.itemsData.profile_id = this.data.entity._id
        this.itemsData.show = true;
        this.itemsData.isAdd = true;
    },
    async getCharacters() {
      this.characters = await this.api.charlist.getCharacters(this.data.entity._id,this.server);
    },
    closeAdd() {
      this.showAdd = false;
      this.showReward = false;
    },
    close() {
      let data = this.data;
      data.show = false;
    },
  },
  async created() {
    this.getCharacters()
  },
  async mounted(){

  }
}
</script>

<style scoped>
.modal{
  display: block;
}

</style>