<template>
  <div v-if="show">
    <div class="container">
      <div class="form-check">
        <input
          class="form-check-input"
          type="checkbox"
          value=""
          v-model="this.byCharId"
          id="flexCheckDefault" />
        <label class="form-check-label capitalize" for="flexCheckDefault">
          Search By Character ID
        </label>
      </div>
      <div class="input-group" v-if="byCharId">
        <div class="form-outline">
          <input
            type="number"
            id="form1"
            class="form-control"
            placeholder="Search"
            v-model="search"
            @change="getProfileById()" />
        </div>
        <button type="button" class="btn btn-primary">
          <i class="fas fa-search"></i>
        </button>
      </div>
    </div>
    <DataTable2
      v-if="!byCharId"
      :headers="headers"
      :actions="actions"
      :api="getProfile"
      :fields="['email']"
      :trigger="trigger" />

    <DataTable
      v-if="byCharId"
      :headers="headers"
      :actions="actions"
      :items="items" />

    <WcoinModal
      :data="wcoinData"
      v-if="wcoinData.show"
      :api="store.custom.apis[store.custom.countryCode]" />
    <ProfileCharactersModal
      :data="this.charData"
      v-if="this.charData.show"
      :api="store.custom.apis[store.custom.countryCode]"
      :server="store.custom.regionserver" />
    <ProfileEquipmentsModal
      :data="this.itemsData"
      v-if="this.itemsData.show"
      :api="
        store.custom.apis[store.custom.countryCode]
      "></ProfileEquipmentsModal>
  </div>
</template>

<script>
  import DataTable2 from "data-table/src/components/DataTable2";
  import DataTable from "data-table/src/components/DataTable";
  import WcoinModal from "../components/WcoinModal";
  import store from "node-back-client/src/store";
  import ProfileCharactersModal from "../components/ProfileCharactersModal";
  import ProfileEquipmentsModal from "../components/ProfileEquipmentsModal";
  export default {
    name: "Account",
    data() {
      return {
        show: false,
        trigger: {},
        store: store,
        wcoinData: {
          show: false,
          entity: null,
        },
        charData: {
          show: false,
          entity: null,
          data: {},
        },
        itemsData: {
          show: false,
          entity: null,
        },
        headers: {
          _id: "ID",
          email: "Email",
          cheaterType: "Cheater Type",
        },
        actions: [
          {
            classNames: "fa fa-coins text-warning",
            callback: this.history,
          },
          {
            classNames: "fa fa-trophy text-success",
            callback: this.edit,
          },
          {
            classNames: "fa-solid fa-users text-dark",
            callback: this.characters,
          },
          {
            classNames: "fa-solid fa-vault text-dark",
            callback: this.profileEquipments,
          },
          {
            classNames: "fa fa-trash text-danger",
            callback: this.remove,
          },
        ],
        byCharId: false,
        search: "",
        items: [],
      };
    },
    methods: {
      async getProfile() {
        if (this.byCharId) {
          return store.custom.apis[store.custom.countryCode].user.getProfiles();
        }
        return await store.custom.apis[
          store.custom.countryCode
        ].user.getProfiles(...arguments);
      },
      async getProfileById() {
        console.log(this.search);
        this.items = [
          await store.custom.apis[
            store.custom.countryCode
          ].user.getProfilesByCharId(store.custom.regionserver, this.search),
        ];
      },
      characters(entity) {
        this.charData.entity = entity;
        this.charData.show = true;
        this.charData.isAdd = true;
      },
      profileEquipments(entity) {
        this.itemsData.entity = entity;
        this.itemsData.show = true;
        this.itemsData.isAdd = true;
      },
      history(entity) {
        this.wcoinData.entity = entity;
        this.wcoinData.show = true;
        this.wcoinData.isAdd = true;
      },

      edit(entity) {
        this.wcoinData.entity = entity;
        this.wcoinData.show = true;
        this.wcoinData.isAdd = false;
      },
      remove(entity) {
        console.log(entity);
      },
    },
    created() {
      store.custom.callbacks.account = () => {
        if (this.trigger.refresh != null) this.trigger.refresh();
      };
      store.custom.callbacks.init = () => {
        this.show = true;
      };

      if (store.custom.isInit) {
        this.show = true;
      }
    },
    unmounted() {
      delete store.custom.callbacks.account();
    },
    components: {
      WcoinModal,
      DataTable2,
      DataTable,
      ProfileCharactersModal,
      ProfileEquipmentsModal,
    },
  };
</script>
