<template>
  <div class="modal bg-opacity-50 bg-black" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">WoCoin History</h5>
          <span aria-hidden="true" role="button" @click="close">&times;</span>
        </div>
        <div class="modal-body">
          <div class="container mb-3">
            <div class="row">
              <button class="btn btn-primary" @click="showAdd = true" v-if="data.isAdd">Add Transaction</button>
              <button class="btn btn-primary" @click="showReward = true" v-else>Add Reward</button>
            </div>
          </div>
          <data-table2
              :api="getTransactions"
              :actions="actions"
              :headers="headers"
              :fields="['_id']"
          :trigger="trigger"/>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" @click="close">Close</button>
        </div>
      </div>
    </div>
  </div>


  <div class="modal bg-opacity-50 bg-black" tabindex="-1" role="dialog" v-if="showAdd">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Add Transaction</h5>
          <span aria-hidden="true" role="button" @click="closeAdd">&times;</span>
        </div>
        <div class="modal-body">
          <div class="form-group mb-3">
            <label class="mb-3">Transaction ID</label>
            <input type="text" class="form-control" v-model="orderid">
          </div>
          <div class="form-group mb-3">
            <label class="mb-3">Amount</label>
            <select class="form-control" v-model="coinValue">
              <option v-for="product in products" v-bind:key="product" :value="product.coinValue">
                {{ product.coinValue }}
              </option>
            </select>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" @click="closeAdd">Close</button>
          <button type="button" class="btn btn-primary" @click="searchTransaction">Search Transaction</button>
          <button type="button" class="btn btn-success" @click="addAppleTransaction">Add Apple Transaction</button>
          <button type="button" class="btn btn-success" @click="addTransaction">Add Transaction</button>
        </div>
      </div>
    </div>
  </div>


  <div class="modal bg-opacity-50 bg-black" tabindex="-1" role="dialog" v-if="showReward">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Add Reward</h5>
          <span aria-hidden="true" role="button" @click="closeAdd">&times;</span>
        </div>
        <div class="modal-body">
          <div class="form-group mb-3">
            <label class="mb-3">Amount</label>
            <select class="form-control" v-model="coinValue">
              <option v-for="product in products" v-bind:key="product" :value="product.coinValue">
                {{ product.coinValue }}
              </option>
            </select>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" @click="closeAdd">Close</button>
          <button type="button" class="btn btn-success" @click="addReward">Add Reward</button>
        </div>
      </div>
    </div>
  </div>


</template>

<script>
import DataTable2 from "data-table/src/components/DataTable2";
import {api} from "../services/api";
import ToastService from 'toast/src/services/toast'
import ModalService from 'modal/src/services/Modal'
export default {
  name: "WcoinModal",
  data(){
    return {
      trigger:{},
      orderid:"",
      coinValue:20,
      showAdd:false,
      showReward:false,
      products:[],
      headers: {
        _id : "ID",
        amount: "value",
      },
      actions: [
        {
          classNames:"fa fa-trash text-danger",
          callback: this.remove
        }
      ],
    }
  },
  props:{
    data: {
      entity:Object,
      show:Boolean
    },
    api: Object
  },
  components:{
    DataTable2
  },
  methods:{
    async addTransaction() {
      try {
        await this.api.coinTransaction.addTransaction(this.data.entity._id, this.orderid, this.coinValue);
        this.trigger.refresh();
        ToastService.Notify({
          title: "Success",
          message: "Transaction successfully added."
        });
        this.closeAdd();
      }
      catch (e) {
        ToastService.Notify({
          title: "Error",
          message: e
        });
        console.log(e);
      }
    },

    async addReward() {
      try {
        await this.api.coinTransaction.addReward(this.data.entity._id, this.coinValue);
        this.trigger.refresh();
        ToastService.Notify({
          title: "Success",
          message: "Reward successfully added."
        });
        this.closeAdd();
      }
      catch (e) {
        ToastService.Notify({
          title: "Error",
          message: e
        });
        console.log(e);
      }
    },
    searchTransaction() {
      let actId = "5755297586881571977";
      let params = `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=600,height=800,left=100,top=100`;
      window.open(`https://play.google.com/console/u/0/developers/${actId}/orders?search=${this.orderid}`,"Transaction",params);
    },
    async addAppleTransaction() {
      let isValid = false;
      let transactions = [];
      try {
        transactions = await this.api.coinTransaction.addTransactionApple(this.data.entity._id,this.orderid);
        isValid = true;
      }
      catch (e) {
        console.log(e);
      }

      if(isValid) {
        ModalService.Show({
          title:`Transaction added`,
          message:`Response:${JSON.stringify(transactions,null,2)}`
        },async () => {
        });

      }
      else {
        ModalService.Show({
          title:`Transaction verify`,
          message:`Transaction ID is ${isValid?"VALID":"INVALID"}!`
        },async () => {
        });
      }
    },
    closeAdd() {
      this.showAdd = false;
      this.showReward = false;
    },
    close() {
      let data = this.data;
      data.show = false;
    },
    async getTransactions(filter,skip,limit) {
      if(filter) {
        filter.profile_id = this.data.entity._id;
        filter.paymentType = 0;
      }
      let transactions = await this.api.coinTransaction.getTransactions(filter,skip,limit);
      transactions.data.reverse();
      return transactions;
    },
    remove(entity) {
      ModalService.Show({
        title:`Delete Transaction`,
        message:`Do you want to remove ${entity._id}?`
      },async () => {
        await this.api.coinTransaction.remove(entity._id);
        this.trigger.refresh();
        ToastService.Notify({
          title: "Success",
          message: "Transaction successfully deleted."
        });
      });
    }
  },
  async created() {
    this.products = await api.getProducts()
    this.products.sort((x,y) => x.coinValue - y.coinValue);
  }
}
</script>

<style scoped>
.modal{
  display: block;
}

</style>