<template>
    <div class="modal bg-opacity-50 bg-black" tabindex="-1" role="dialog">
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Character Equipments</h5>
            <span aria-hidden="true" role="button" @click="close">&times;</span>
          </div>
          <div class="modal-body">
            <div class="container mb-3">
              <div class="row">
                <button class="btn btn-primary" @click="showAdd = true" v-if="data.isAdd">Add Profile Equipment</button>
              </div>
            </div>
            <data-table
              :actions="actions"
              :headers="headers"
              :items="this.coinEquipments"/>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" @click="close">Close</button>
          </div>
        </div>
      </div>
    </div>
  
  
    
    <div class="modal bg-opacity-50 bg-black" tabindex="-1" role="dialog" v-if="showAdd"> <!--Add Equipment-->
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Add Equipment</h5>
            <span aria-hidden="true" role="button" @click="closeAdd">&times;</span>
          </div>
          <div class="modal-body">
           
            
            <div class="form-group">
                <label>Profile Item</label>
                <select name="inpsel_equipment_id" class="form-control" id="inpsel_equipid" v-model="this.model.equipment_id">
                    <option selected>Select Item</option>
                    <option :value="coinEquip.id" v-for="coinEquip in this.coinEquipList" :key="coinEquip" >{{ coinEquip.name }}</option>
                </select>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" @click="closeAdd">Close</button>
            <button type="button" class="btn btn-success" @click="this.addProfileEquipment()">Add Item</button>
          </div>
        </div>
      </div>
    </div>
  
    
</template>
  
<script>
  import DataTable from "data-table/src/components/DataTable";
   import ToastService from 'toast/src/services/toast'
  // import ModalService from 'modal/src/services/Modal'
  export default {
    name: "WcoinModal",
    data(){
      return {
        trigger:{},
        showAdd:false,
        showReward:false,
        coinEquipments:{},
        coinEquipList:{},
        headers: {
          _id : "ID",
          name: "Name",
        },
        actions: [
          {
            classNames:"fa-solid fa-pen-to-square text-success",
            callback: this.updateEquipment
          }
        ],
        equipmentData:{
            show:false,
            entity:null,
        },
        equipmentToUpdate:{
            show:false,
            entity:null,
        },
        model:{}
      }
    },
    props:{
        data: {
            entity:Object,
            show:Boolean,
        },
        api: Object,
    },
    components:{
        DataTable
    },
    methods:{
        async getProfileEquipments() {
            this.coinEquipments = await this.api.profileequipment.getProfileEquipments(this.data.entity._id);
        },
        async popProfileEquipments(){
          let data = await this.api.profileequipment.getProfileItems();
          return data;
        },
        async addProfileEquipment(){
          let data = {
            profile_id:this.data.entity._id,
            coin_equipment_id: this.model.equipment_id 
          }

          let res = await this.api.profileequipment.addProfileEquipment(data)
          if(res.message === 'Success'){
              ToastService.Notify({
                title: "Success",
                message: "Item Added!"
              });
                this.showAdd = false;
            }  
            else{
              ToastService.Notify({
                title: "Error",
                message: "Unable to save."
              });
                this.showAdd = false;
            }
        },  
        closeUpdate() {
            this.equipmentToUpdate.show = false;
        },
        closeAdd() {
            this.showAdd = false;
        },
        close() {
            let data = this.data;
            data.show = false;
        },
        
    },
    async mounted(){
        await this.getProfileEquipments();
        this.coinEquipList = await this.popProfileEquipments();
    }
}
</script>
  
  <style scoped>
    .modal{
            display: block;
    }
  
  </style>